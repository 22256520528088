import { useLoadScript } from '@react-google-maps/api';

const libraries = ['places'];

export function GoogleMapsProvider({ children }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries,
    googleMapsClientId: import.meta.env.VITE_GOOGLE_MAPS_CLIENT_ID
  });

  if (loadError) {
    console.error('Google Maps failed to load:', loadError);
    return (
      <div className="h-full w-full flex items-center justify-center bg-gray-100 rounded-lg p-4">
        <p className="text-gray-500">Unable to load maps. Please try again later.</p>
      </div>
    );
  }

  if (!isLoaded) {
    return (
      <div className="h-full w-full flex items-center justify-center bg-gray-100 rounded-lg">
        <p className="text-gray-500">Loading maps...</p>
      </div>
    );
  }

  return children;
}