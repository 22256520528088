import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const initErrorMonitoring = () => {
  if (import.meta.env.PROD && import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: import.meta.env.MODE,
      beforeSend(event) {
        // Remove sensitive information
        if (event.user) {
          delete event.user.email;
          delete event.user.ip_address;
        }
        return event;
      }
    });
  }
};

export const captureError = (error, context = {}) => {
  console.error('Error:', error);

  if (import.meta.env.PROD && import.meta.env.VITE_SENTRY_DSN) {
    Sentry.withScope(scope => {
      Object.entries(context).forEach(([key, value]) => {
        scope.setExtra(key, value);
      });
      Sentry.captureException(error);
    });
  }

  return getErrorMessage(error);
};

const getErrorMessage = (error) => {
  if (error.response?.data?.message) {
    return error.response.data.message;
  }

  switch (error.code) {
    case 'ERR_NETWORK':
      return 'Network error. Please check your connection.';
    case 'ERR_BAD_REQUEST':
      return 'Invalid request. Please check your input.';
    case 'ERR_SERVER':
      return 'Server error. Please try again later.';
    case 'ERR_UNAUTHORIZED':
      return 'Please log in to continue.';
    case 'ERR_FORBIDDEN':
      return 'You do not have permission to perform this action.';
    case 'ERR_NOT_FOUND':
      return 'The requested resource was not found.';
    case 'ERR_TIMEOUT':
      return 'Request timed out. Please try again.';
    default:
      return error.message || 'An unexpected error occurred.';
  }
};