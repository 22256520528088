import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

// Public routes
import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Login from '../pages/Login';
import Signup from '../pages/Signup';
import Activities from '../pages/Activities';
import ActivityDetails from '../pages/ActivityDetails';
import Search from '../pages/Search';
import Help from '../pages/Help';
import Terms from '../pages/Terms';
import Privacy from '../pages/Privacy';
import NotFound from '../pages/NotFound';

// Admin routes
import Admin from '../pages/Admin';
import AdminDashboard from '../pages/Admin/AdminDashboard';
import AdminUsers from '../pages/Admin/AdminUsers';
import AdminActivities from '../pages/Admin/AdminActivities';
import AdminBookings from '../pages/Admin/AdminBookings';
import AdminReviews from '../pages/Admin/AdminReviews';
import AdminPayments from '../pages/Admin/AdminPayments';
import AdminReports from '../pages/Admin/AdminReports';
import AdminSecurity from '../pages/Admin/AdminSecurity';
import AdminSettings from '../pages/Admin/AdminSettings';

// Provider routes
import Provider from '../pages/Provider';
import ProviderDashboard from '../pages/Provider/ProviderDashboard';
import ProviderActivities from '../pages/Provider/ProviderActivities';
import ProviderBookings from '../pages/Provider/ProviderBookings';
import ProviderCustomers from '../pages/Provider/ProviderCustomers';
import ProviderAnalytics from '../pages/Provider/ProviderAnalytics';
import ProviderEarnings from '../pages/Provider/ProviderEarnings';
import ProviderReviews from '../pages/Provider/ProviderReviews';
import ProviderMessages from '../pages/Provider/ProviderMessages';
import ProviderProfile from '../pages/Provider/ProviderProfile';
import ProviderSettings from '../pages/Provider/ProviderSettings';

// Traveler routes
import Traveler from '../pages/Traveler';
import TravelerDashboard from '../pages/Traveler/TravelerDashboard';
import TravelerBookings from '../pages/Traveler/TravelerBookings';
import TravelerSaved from '../pages/Traveler/TravelerSaved';
import TravelerReviews from '../pages/Traveler/TravelerReviews';
import TravelerFavorites from '../pages/Traveler/TravelerFavorites';
import TravelerSettings from '../pages/Traveler/TravelerSettings';

function ProtectedRoute({ children, allowedRoles = [] }) {
  const { user, isAuthenticated } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (allowedRoles.length > 0 && !allowedRoles.includes(user?.currentRole)) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default function AppRoutes() {
  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/activities" element={<Activities />} />
      <Route path="/activities/:id" element={<ActivityDetails />} />
      <Route path="/search" element={<Search />} />
      <Route path="/help" element={<Help />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />

      {/* Admin routes */}
      <Route
        path="/admin"
        element={
          <ProtectedRoute allowedRoles={['ADMIN']}>
            <Admin />
          </ProtectedRoute>
        }
      >
        <Route index element={<AdminDashboard />} />
        <Route path="users" element={<AdminUsers />} />
        <Route path="activities" element={<AdminActivities />} />
        <Route path="bookings" element={<AdminBookings />} />
        <Route path="reviews" element={<AdminReviews />} />
        <Route path="payments" element={<AdminPayments />} />
        <Route path="reports" element={<AdminReports />} />
        <Route path="security" element={<AdminSecurity />} />
        <Route path="settings" element={<AdminSettings />} />
      </Route>

      {/* Provider routes */}
      <Route
        path="/provider"
        element={
          <ProtectedRoute allowedRoles={['PROVIDER']}>
            <Provider />
          </ProtectedRoute>
        }
      >
        <Route index element={<ProviderDashboard />} />
        <Route path="activities" element={<ProviderActivities />} />
        <Route path="bookings" element={<ProviderBookings />} />
        <Route path="customers" element={<ProviderCustomers />} />
        <Route path="analytics" element={<ProviderAnalytics />} />
        <Route path="earnings" element={<ProviderEarnings />} />
        <Route path="reviews" element={<ProviderReviews />} />
        <Route path="messages" element={<ProviderMessages />} />
        <Route path="profile" element={<ProviderProfile />} />
        <Route path="settings" element={<ProviderSettings />} />
      </Route>

      {/* Traveler routes */}
      <Route
        path="/traveler"
        element={
          <ProtectedRoute allowedRoles={['TRAVELER']}>
            <Traveler />
          </ProtectedRoute>
        }
      >
        <Route index element={<TravelerDashboard />} />
        <Route path="bookings" element={<TravelerBookings />} />
        <Route path="saved" element={<TravelerSaved />} />
        <Route path="reviews" element={<TravelerReviews />} />
        <Route path="favorites" element={<TravelerFavorites />} />
        <Route path="settings" element={<TravelerSettings />} />
      </Route>

      {/* 404 route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}