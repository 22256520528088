import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { auth } from '../config/firebase';
import toast from 'react-hot-toast';

const ROLES = {
  ADMIN: {
    name: 'Platform Administrator',
    theme: 'admin',
    color: '#40C5C5',
    permissions: ['manage_users', 'manage_activities', 'manage_bookings', 'platform_settings']
  },
  PROVIDER: {
    name: 'Activity Provider',
    theme: 'provider',
    color: '#003580',
    permissions: ['manage_own_activities', 'manage_own_bookings', 'view_earnings']
  },
  TRAVELER: {
    name: 'Traveler',
    theme: 'traveler',
    color: '#FF9900',
    permissions: ['book_activities', 'manage_own_bookings', 'write_reviews']
  }
};

const useAuthStore = create(
  persist(
    (set, get) => ({
      user: null,
      roles: [],
      currentRole: null,
      isAuthenticated: false,
      isLoading: false,

      login: async (userData) => {
        try {
          set({ isLoading: true });
          
          let role = 'TRAVELER';
          if (userData.email.includes('admin')) {
            role = 'ADMIN';
          } else if (userData.email.includes('provider')) {
            role = 'PROVIDER';
          }

          const user = {
            ...userData,
            role,
            currentRole: role
          };

          set({ 
            user,
            roles: [role],
            currentRole: role,
            isAuthenticated: true,
            isLoading: false
          });

          toast.success(`Welcome back, ${user.name || user.email}!`);
          return user;
        } catch (error) {
          const message = error.message || 'Login failed';
          toast.error(message);
          set({ isLoading: false });
          throw error;
        }
      },

      signup: async (userData) => {
        try {
          set({ isLoading: true });
          
          if (!userData.role) {
            throw new Error('Role is required');
          }

          const user = {
            ...userData,
            id: Date.now().toString(),
            currentRole: userData.role
          };

          set({ 
            user,
            roles: [userData.role],
            currentRole: userData.role,
            isAuthenticated: true,
            isLoading: false
          });

          toast.success('Account created successfully!');
          return user;
        } catch (error) {
          const message = error.message || 'Signup failed';
          toast.error(message);
          set({ isLoading: false });
          throw error;
        }
      },

      logout: async () => {
        try {
          set({ isLoading: true });
          await auth.signOut();
          set({
            user: null,
            roles: [],
            currentRole: null,
            isAuthenticated: false,
            isLoading: false
          });
          toast.success('Successfully logged out');
        } catch (error) {
          console.error('Error during logout:', error);
          set({
            user: null,
            roles: [],
            currentRole: null,
            isAuthenticated: false,
            isLoading: false
          });
          toast.error('Error during logout, but you have been signed out');
        }
      },

      switchRole: async (newRole) => {
        const state = get();
        if (!state.user || !state.roles.includes(newRole)) {
          throw new Error('Invalid role');
        }

        set({ 
          currentRole: newRole,
          user: { ...state.user, currentRole: newRole }
        });
        
        toast.success(`Switched to ${ROLES[newRole].name} role`);
      },

      hasRole: (role) => {
        const state = get();
        return state.roles.includes(role);
      },

      hasPermission: (permission) => {
        const state = get();
        if (!state.currentRole) return false;
        return ROLES[state.currentRole].permissions.includes(permission);
      },

      getRoleName: () => {
        const state = get();
        if (!state.currentRole) return null;
        return ROLES[state.currentRole].name;
      },

      getRoleColor: () => {
        const state = get();
        if (!state.currentRole) return '#7C7F86';
        return ROLES[state.currentRole].color;
      },

      getAllRoles: () => {
        const state = get();
        return state.roles.map(role => ({
          id: role,
          ...ROLES[role]
        }));
      }
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({ 
        user: state.user,
        roles: state.roles,
        currentRole: state.currentRole,
        isAuthenticated: state.isAuthenticated
      })
    }
  )
);

export const useAuth = () => useAuthStore();