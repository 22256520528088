import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import MainNav from './Navigation/MainNav';
import MobileNav from './Navigation/MobileNav';
import UserMenu from './Navigation/UserMenu';
import RoleBasedNavigation from './Navigation/RoleBasedNavigation';
import Footer from './Footer';
import { useAuth } from '../hooks/useAuth';
import Logo from './Logo';

export default function Layout({ children }) {
  const { user } = useAuth();

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Disclosure as="nav" className="bg-white shadow">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex">
                  <div className="flex flex-shrink-0 items-center">
                    <Logo />
                  </div>
                  <MainNav />
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  <UserMenu />
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            <MobileNav />
          </>
        )}
      </Disclosure>

      {user && <RoleBasedNavigation />}

      <main className="flex-grow py-10">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">{children}</div>
      </main>

      <Footer />
    </div>
  );
}