import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { usePayments } from '../../hooks/usePayments';

const DEFAULT_SCHEDULE = {
  frequency: 'weekly',
  minimumAmount: 50,
  processingFee: 2.9,
  platformFee: 10
};

export default function PayoutScheduleModal({ isOpen, onClose }) {
  const { updatePayoutSchedule, isLoading } = usePayments();
  const [schedule, setSchedule] = useState(DEFAULT_SCHEDULE);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updatePayoutSchedule(schedule);
    onClose();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                      Configure Payout Schedule
                    </Dialog.Title>

                    <form onSubmit={handleSubmit} className="mt-6 space-y-6">
                      <div>
                        <label htmlFor="frequency" className="block text-sm font-medium text-gray-700">
                          Payout Frequency
                        </label>
                        <select
                          id="frequency"
                          className="input mt-1"
                          value={schedule.frequency}
                          onChange={(e) => setSchedule({ ...schedule, frequency: e.target.value })}
                        >
                          <option value="weekly">Weekly</option>
                          <option value="biweekly">Bi-weekly</option>
                          <option value="monthly">Monthly</option>
                        </select>
                      </div>

                      <div>
                        <label htmlFor="minimumAmount" className="block text-sm font-medium text-gray-700">
                          Minimum Payout Amount ($)
                        </label>
                        <input
                          type="number"
                          id="minimumAmount"
                          className="input mt-1"
                          value={schedule.minimumAmount}
                          onChange={(e) => setSchedule({ ...schedule, minimumAmount: Number(e.target.value) })}
                          min="0"
                          step="10"
                        />
                      </div>

                      <div>
                        <label htmlFor="processingFee" className="block text-sm font-medium text-gray-700">
                          Processing Fee (%)
                        </label>
                        <input
                          type="number"
                          id="processingFee"
                          className="input mt-1"
                          value={schedule.processingFee}
                          onChange={(e) => setSchedule({ ...schedule, processingFee: Number(e.target.value) })}
                          min="0"
                          step="0.1"
                        />
                      </div>

                      <div>
                        <label htmlFor="platformFee" className="block text-sm font-medium text-gray-700">
                          Platform Fee (%)
                        </label>
                        <input
                          type="number"
                          id="platformFee"
                          className="input mt-1"
                          value={schedule.platformFee}
                          onChange={(e) => setSchedule({ ...schedule, platformFee: Number(e.target.value) })}
                          min="0"
                          step="1"
                        />
                      </div>

                      <div className="mt-6 flex justify-end space-x-3">
                        <button
                          type="submit"
                          className="btn-primary"
                          disabled={isLoading}
                        >
                          {isLoading ? 'Saving...' : 'Save Changes'}
                        </button>
                        <button
                          type="button"
                          className="btn-secondary"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}